import { Fragment } from 'react'
import { useUserContext } from '@introducers-contexts'
import { Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { Icon, IconTypes, Nav, OptionsNavLink } from '@acre/design-system'

import UserSettingsMenu from './UserSettingsNav'

const MainNav = () => {
  const user = useUserContext()

  return (
    <Nav
      left={[
        <Fragment key="logo">
          <Box display="flex" ml={4} mr={1.5}>
            <Icon name={IconTypes.AcreSquareLogo} />
          </Box>
        </Fragment>,
        <OptionsNavLink
          to="/dashboard"
          id="NavDashboard"
          key="NavDashboard"
          isActive={(location) => location.startsWith('/dashboard')}
        >
          <FormattedMessage id="nav.referrals" />
        </OptionsNavLink>,
        <OptionsNavLink
          to={`/reports/${user?.organisation_id}`}
          id="NavReport"
          key="NavReport"
          isActive={(location) => location.includes(`/reports/${user?.organisation_id}`)}
        >
          <FormattedMessage id="nav.reports" />
        </OptionsNavLink>,
      ]}
      right={[
        <UserSettingsMenu
          key="NavUserSettings"
          firstName={user?.first_name}
          lastName={user?.last_name}
          organisationId={user?.organisation_id}
          userId={user?.id}
        />,
      ]}
    ></Nav>
  )
}

export default MainNav
