import { fetchClients } from '../api/client'
import { createMortgageHelper, fetchMortgages, updateMortgages } from '../api/mortgage'
import { addProperty, fetchProperties, fetchProperty, updateProperty } from '../api/property'
import {
  addPropertyCp,
  fetchClientProperties,
  fetchMortgagesForPropertiesCP,
  fetchPropertyCP,
  updatePropertyCP,
} from '../api_client_portal'
import { Resolvers } from '../generated/resolvers'
import { mortgageLoader } from '../loaders/mortgage'
import { formatMortgage } from '../utils/schemaMapping/mortgage'
import { fetchVersionedCaseChildEntities } from '../utils/versionedCaseChildEntities'

const PropertyResolver: Resolvers = {
  Query: {
    property: (_parent, { id, version }) => fetchProperty(id, version),
    propertyCp: (_parent, { id }) => fetchPropertyCP(id),
    properties: () => fetchProperties(),
    propertiesCp: (_parent, { id }) => fetchClientProperties(id!),
  },
  Property: {
    mortgages: async ({ property_id, transition_mortgage_versions, case_status }) => {
      const mortgages =
        case_status &&
        transition_mortgage_versions &&
        (await fetchVersionedCaseChildEntities('mortgage', {
          status: case_status,
          transitionVersions: transition_mortgage_versions,
        }))

      if (mortgages) {
        return mortgages.filter((mortgage) => mortgage.property_secured_ids?.includes(property_id))
      }

      if (!mortgages) {
        return fetchMortgages({ filter_property_secured_id: property_id })
      }

      return mortgages || null
    },
    mortgagesCp: async (parent) => {
      const { property_id } = parent
      const data = await fetchMortgagesForPropertiesCP(property_id)
      const mortgages = data?.mortgages?.map((mortgage) => formatMortgage(mortgage)) || []
      return mortgages
    },
    registered_owners: async (parent) => {
      const { registered_owners_details } = parent

      if (!registered_owners_details) {
        return null
      }

      const clients = await fetchClients({
        client_ids: registered_owners_details,
        client_details: true,
      })

      return clients
    },
  },
  Mutation: {
    addProperty: (_parent, { input }) => addProperty(input),
    addPropertyCp: (_parent, { input }) => addPropertyCp(input),
    updateProperty: (_parent, { propertyId, input }) => updateProperty(input, propertyId),
    updatePropertyCp: (_parent, { propertyId, input }) => updatePropertyCP(input, propertyId),
    mutatePropertyAndMortgages: async (_parent, { input }) => {
      const { propertyId, propertyInput, updateMortgageInput, createMortgageInput } = input

      const property = propertyInput && propertyId ? await updateProperty(propertyInput, propertyId) : undefined

      const updatedMortgages = updateMortgageInput ? await updateMortgages(updateMortgageInput) : undefined

      const createdMortgage = createMortgageInput ? await createMortgageHelper(createMortgageInput) : undefined

      if (createMortgageInput || updateMortgageInput) {
        mortgageLoader.clearAll()
      }

      return { property, createdMortgage, updatedMortgages }
    },
  },
}

export default PropertyResolver
