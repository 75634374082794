import React, { useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { testHandle } from '@acre/utils'
import { Maybe } from '@acre/graphql'
import envConfig from '@acre/config'
import {
  AvatarNameBuilder,
  Button,
  BUTTON_CLEAR,
  ButtonOverflow,
  ColourId,
  COMPACT_LEFT_ALIGNED,
} from '@acre/design-system'

import { ExternalLink } from './common.styles'

type Props = {
  disabled?: boolean
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  organisationId?: Maybe<string>
  userId?: Maybe<string>
}

const UserSettingsMenu = ({ disabled, firstName, lastName }: Props) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => setIsOpen(false)

  const userSettingsIcon = (
    <AvatarNameBuilder
      className="userName"
      firstName={firstName!}
      lastName={lastName!}
      colour={ColourId.LightBlue}
      hideUserName
    />
  )

  return (
    <Box mr={4} ml={1.5}>
      <ButtonOverflow
        id="NavGlobalUserSettings"
        isOpen={isOpen}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(true)
        }}
        onClose={() => handleClose()}
        customIcon={userSettingsIcon}
        disabled={disabled}
        style={BUTTON_CLEAR}
        fontColor={theme.colours.danger}
        fontSize={theme.typography.pxToRem(16)}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <ExternalLink href={envConfig.LOGOUT_URL} id="SignOutNav" data-testid={testHandle('SignOutNav')}>
            <Button variant={COMPACT_LEFT_ALIGNED} onClick={handleClose} buttonStyle="popOver" fullWidth>
              <FormattedMessage id="nav.settings.signOut" />
            </Button>
          </ExternalLink>
        </Box>
      </ButtonOverflow>
    </Box>
  )
}

export default UserSettingsMenu
