import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import Cookies from 'js-cookie'

import {
  GetBrokersEulaDocument,
  GetBrokersEulaQuery,
  GetCurrentUserQuery,
  Maybe,
  QueryBrokersEulaArgs,
  User,
} from '@acre/graphql'
// import config from '@acre/config'

export const filterIntroducerClientRecordPerEnv = (/*user?: Maybe<GetCurrentUserQuery['currentUser']>*/) => {
  // TEMPORARY CHANGE until we re-enable this EULA feature

  // client record can exist with same email address as introducers so we want to check if it's originated from acre

  // const introducerClientRecord = user?.client_record?.find(
  //   (client) => client?.details?.client_originator === config.CLIENT_ORIGINATOR,
  // )

  // return introducerClientRecord
  return undefined as User | undefined
}

export const useEula = ({ user }: { user?: Maybe<GetCurrentUserQuery['currentUser']> }) => {
  const [doc, setDoc] = useState<Maybe<GetBrokersEulaQuery['brokersEula']>>()
  const [signedEula, setSignedEula] = useState<boolean>(false)
  const introducerClientRecord = filterIntroducerClientRecordPerEnv()

  const [getIntroducersEula, { loading }] = useLazyQuery<GetBrokersEulaQuery, QueryBrokersEulaArgs>(
    GetBrokersEulaDocument,
    {
      onCompleted: ({ brokersEula }) => {
        // if eula doesn't exist, sentry has logged it, we want to continue uninterrupted user flow
        // or if verification count is more than 1 than we know user has already accepted eula in the past
        if (
          !introducerClientRecord?.id ||
          !brokersEula ||
          (brokersEula?.verification_count && brokersEula?.verification_count >= 1)
        ) {
          Cookies.set(`eula-${introducerClientRecord?.id}`, JSON.stringify({ signed: true }), { expires: 30 })
          return setSignedEula(true)
        }
        // otherwise we render eula for acceptance
        if (brokersEula) {
          return setDoc(brokersEula)
        }
      },
    },
  )

  useEffect(() => {
    if (introducerClientRecord?.id) {
      const cookieValue = Cookies.get(`eula-${introducerClientRecord?.id}`)
      const eulaSigned = cookieValue && JSON.parse(cookieValue).signed === true
      if (eulaSigned) {
        return setSignedEula(true)
      } else {
        getIntroducersEula({
          variables: {
            brokerClientId: introducerClientRecord?.id,
          },
        })
      }
    }

    // if Client ID doesn't exist, sentry has logged it, we want to continue uninterrupted user flow
    if (user && !introducerClientRecord?.id) {
      return setSignedEula(true)
    }
  }, [user])
  return {
    loading,
    doc,
    signedEula,
    setSignedEula,
  }
}
