import { formatAsCurrency } from '@acre/graphql'

export enum CaseStatusColumns {
  Lead = 'LEAD',
  PreRecommendation = 'PRE_RECOMMENDATION',
  PreApplication = 'PRE_APPLICATION',
  ApplicationSubmitted = 'APPLICATION_SUBMITTED',
  AwaitingValuation = 'AWAITING_VALUATION',
  AwaitingOffer = 'AWAITING_OFFER',
  Offered = 'OFFER_RECEIVED',
  Exchange = 'EXCHANGE',
  Complete = 'COMPLETE',
}

export const defaultValue = {
  value: formatAsCurrency(0),
  count: 0,
}
