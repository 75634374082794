import { Locales } from '@acre/utils'

export const messagesIntroducers = {
  [Locales.GB]: {
    login: {
      title: 'Welcome to acre',
      goToDashboard: 'Go to dashboard',
      form: {
        email: {
          label: 'Email address',
          placeholder: 'you@email.com',
          error: {
            one: 'The email address entered was not recognised',
            two: `
              The email address entered was not recognised, please
              retry or {getInTouch}
            `,
            getInTouch: 'get in touch',
          },
        },
        button: 'Next',
        rememberMe: 'Remember me',
      },
    },
    eula: {
      header: 'Acre Software Licence Agreement',
      confirmation: 'I accept the terms in the Licence Agreement',
      submit: 'Confirm and continue',
    },
    referredCasesTable: {
      clients: 'Clients',
      property: 'Property',
      caseType: 'Case type',
      loanRequired: 'Loan required',
      productExpiry: 'Product Expiry',
      company: 'Company',
      created: 'Created',
      status: 'Status',
    },
    productTypes: {
      noReasonProvided: 'No reason selected',
      firstTimeBuy: 'FTB or Purchase only',
      houseMove: 'House move',
      remortgage: 'Remortgage',
      buyToLet: 'Buy-to-Let',
      buyToLetRemortgage: 'Buy-to-Let remortgage',
      equityRelease: 'Equity release',
      furtherAdvance: 'Additional borrowing',
      protection: 'Health and Protection',
      bridging: 'Bridging finance',
      commercial: 'Commercial mortgage',
      businessProtection: 'Business protection',
    },
    newCase: {
      createNewReferral: 'Create new referral',
      createReferral: 'Create referral',
      name: 'First name',
      lastName: 'Last name',
      address: 'Address',
      DOB: 'Date of birth',
      employmentType: 'Employment type',
      youNeedOneOfTheFollowing: 'You need to capture at least one of the following:',
      phone: 'Phone',
      email: 'Email',
      companyToreferTo: 'Company to refer to',
      referralType: 'Referral type',
      purchaseProperty: 'Purchase property',
      loanRequired: 'Loan required',
      notes: 'Notes',
    },
    employmentStatus: {
      employed: 'Employed',
      selfEmployed: 'Self-employed',
      retired: 'Retired',
      homemaker: 'Not in employment',
    },

    cases: {
      referrals: 'Referrals',
      referredCases: 'Referred cases',
      status: {
        preRecommendation: 'Pre-recommendation',
        preApplication: 'Pre-application',
        postApplication: 'Post-application',
        notProceeding: 'Not proceeding',
        review: 'Review',
        importedComplete: 'Imported Complete',
        importing: 'Importing',
        postRecommendationReview: 'Review',
        lead: 'Lead',
        applicationSubmitted: 'Application Submitted',
        awaitingValuation: 'Awaiting Valuation',
        awaitingOffer: 'Awaiting Offer',
        offered: 'Offered',
        exchange: 'Exchanged',
        complete: 'Complete',
      },
    },
    nav: {
      referrals: 'Referrals',
      reports: 'Reports',
      settings: {
        signOut: 'Sign out',
      },
    },
    mi: {
      caseDetails: 'Case Details',
      firmStats: 'Summary Statistics',
      advisorStats: 'Summary Statistics by Advisor',
      applyFilters: 'Apply filters',
      noReports: 'No reports',
      total: 'Total',
      revenueTypeTitle: 'Showing',
      predictedRevenueTypes: {
        revenue: 'Mortgage volume',
        predictedRevenue: 'Predicted revenue',
      },
      subnav: {
        title: 'Reports',
        currentPipeline: 'Current Pipeline',
        cancellationReason: 'Cancellation Reason',
        caseStatusWaterfall: 'Case Status Waterfall',
        revenue: 'Revenue',
        other: 'Download Reports',
        acrecsv: 'Global CSV Download',
        filterButton: 'Filter Report',
        customiseButton: 'Customise Report',
      },
      caseStatus: {
        PRE_RECOMMENDATION: 'Pre-recommendation',
        PRE_APPLICATION: 'Pre-application',
        LEAD: 'Lead',
        APPLICATION_SUBMITTED: 'Application Submitted',
        AWAITING_VALUATION: 'Awaiting Valuation',
        AWAITING_OFFER: 'Awaiting Offer',
        OFFER_RECEIVED: 'Offered',
        EXCHANGE: 'Exchanged',
        COMPLETE: 'Complete',
      },
      cancellationReason: {
        NO_RESPONSE: 'No Response',
        CLIENT_DECLINED_PRODUCT: 'Customer Declined Product',
        NO_PRODUCT_AVAILABLE: 'No Product Available',
        DUPLICATE_CASE: 'Duplicate Case',
        INCORRECT_CONTACT_DETAILS: 'Incorrect Contact Details',
        CLIENT_NO_LONGER_REQUIRES_PRODUCT: 'Client No Longer Requires Product',
        CLIENT_OBTAINED_PRODUCT_ELSEWHERE: 'Client Obtained Product Elsewhere',
        OTHER: 'Other',
        TOTAL: 'Total',
      },
      filterBar: {
        advisors: 'Advisors',
        lenders: `Lenders`,
        createdDate: `Case created date`,
        paymentCreatedDate: `Payment created date`,
        paymentCollectedDate: `Payment collected date`,
        caseTypes: `Case types`,
        revenueType: 'Revenue types',
        paymentDate: 'Payment date',
        paymentStatus: 'Payment status',
        clearAll: 'Clear all',
        selectAll: 'Select all',
        applyFilters: 'Apply filters',
        resetAll: 'Reset all',
        introducers: 'Introducers',
        caseStatus: 'Case status',
      },
      table: {
        total: 'Total',
      },
      modal: {
        heading: 'Customise report view',
        viewByTitle: 'By:',
        viewDatesByTitle: 'Showing:',
      },
      viewDateBy: {
        caseCreatedAt: 'Case Created Date',
        ledgerCreatedAt: 'Payment Created Date',
        receivedDate: 'Payment Received Date',
      },
      viewBy: {
        advisor: 'Advisor',
        lender: 'Lender',
        introducer: 'Introducer',
        caseType: 'Case Type',
        yearMonth: 'Month case was created',
        ledgerType: 'Revenue Type',
      },
      pages: {
        currentPipeline: {
          title: 'Current pipeline',
        },
        cancellationReason: {
          title: 'Case cancellation reason',
        },
        caseWaterfall: {
          title: 'Case status waterfall',
        },
        revenue: {
          title: 'Revenue',
        },
        organisationcsv: {
          title: 'Management Information',
          titleSubHeader:
            'Here are some handy reports for you to download. If there is something more specific you need, let us know.',
          openCasesAggregate: 'Aggregate open cases',
          openCasesLabel: 'This provides a list of totals for open cases per broker',
          allOpenCases: 'All open cases',
          allOpenCasesLabel: 'This provides a list of all open cases in the firm',
          completedCasesAggregate: 'Aggregate completed cases',
          completedCasesAggrgateLabel:
            'This provides a list of total complete cases per broker within a specified date range',
          allCompletedCases: 'All completed cases',
          allCompletedCasesLabel:
            'This provides a list of total complete cases in the firm within a specified date range',
          downloadReport: 'Download report',
          dateModal: {
            heading: 'Select date range',
            from: 'From',
            to: 'To',
          },
        },
        other: {
          title: 'Select a report to download',
          columns: {
            title: 'Report type',
            description: 'Description',
          },
          actions: {
            download: 'Download',
          },
          forms: {
            download: {
              title: 'Download report',
              submit: 'Download CSV',
            },
          },
        },
        globalcsv: {
          title: 'Acre Global MI Download',
          titleSubHeader: 'This facility is strictly for the use of authorised Acre personnel.',
          casesAggregate: 'Statistics by Advisor for all cases',
          casesAggregateLabel: 'Statistics by Advisor for all cases',
          allOpenCases: 'All cases',
          allOpenCasesLabel: 'Summary details for each case.',
          users: 'Users',
          userLabel: 'Name, role and email for all current users',
          downloadReport: 'Download report',
        },
      },
    },
    common: {
      addresses: 'Addresses',
      addressLookup: {
        line1: 'Address line 1',
        line2: 'Address line 2',
        line3: 'Address line 3',
        town: 'Town',
        postcode: 'Postcode',
        country: 'Country',
        lookupLabel: 'Address lookup',
        lookupButton: 'Lookup',
        manualButton: 'Enter manually',
        noResults: 'No results. Please enter your address manually.',
        employerLabel: 'Employer Address',
      },
    },
    errors: {
      email: 'Please enter a valid email address',
      generic: 'Something went wrong',
      inputMustBeLetter: 'Input must be a letter',
      inputMustBeNumber: 'Input must be a number',
      inputMustBePositiveNumber: 'Input must be a positive number',
      insufficient: 'Amount is insufficient',
      greaterThan: '{first} must be greater than {second}',
      greaterThanZero: '{first} must be greater than zero {second}',
      integer: '{label} must be a whole number',
      interestRateTooHighForMortgage: 'Interest rate is too high for a mortgage (>40%)',
      invalidMonth: 'Please enter a valid number. This should be between 1 and 900',
      lessThan: '{first} must be less than {second}',
      noOptions: 'No options found',
      nonNegative: '{label} cannot be negative',
      percentage: 'Entered percentage must be 100% or less',
      phone: 'Please enter a valid phone number',
      positive: '{label} must be positive',
      radio: 'Please select an option',
      required: 'This field is required',
      select: 'Please select an item',
      validValue: 'Please enter a valid value',
      invalidFormat: 'Invalid format',
      clientDobExceedsMoveInDate: 'Move in date cannot be before date of birth',
      mobileOrOfficePhoneRequired: 'Mobile or office phone number is required',
    },

    DateError: {
      underAge: 'Must be 18 years or older',
      overAge: 'Date must be within last 125 years',
      future: 'Date cannot be in the future',
      past: 'Date cannot be in the past',
      mustBeInFuture: 'Date must be in the future',
      input: 'Input must be number',
      day: 'Enter day of the month (1-31)',
      month: 'Enter month (1-12)',
      generic: 'Invalid date',
      before: 'Must be before the end date',
      after: 'Must be after the start date',
      beforeLabel: '{first} must be before {second}',
      afterLabel: '{first} must be after {second}',
      beforeTermEnd: 'Initial period must end before mortgage term',
      endAfterStartDate: 'End date must be after start date',
      startBeforeEndDate: 'Start date must be before end date',
    },
    select: {
      message: 'Select an option',
    },
    accessibility: {
      closeModal: 'Close modal',
    },
    errorPage: {
      text: 'Looks like something went wrong!',
      button: 'Go back',
    },
    dateRangeSelector: {
      applyFilter: 'Apply filter',
      from: 'From',
      to: 'To',
      options: {
        today: 'Today',
        thisWeek: 'This week',
        thisMonth: 'This month',
        thisYear: 'This year',
        dateRange: 'Date range',
      },
    },

    searchSelector: {
      clearAll: 'Clear all',
      selectAll: 'Select all',
    },
    accounting: {
      search: 'Search',
      amount: 'Amount',
      amountDue: 'Amount',
      dueDate: 'Due',
      startDate: 'Start date',
      endDate: 'End date',
      amountPaid: 'Amount paid',
      amountDatePaid: 'Amount / Date paid',
      settledDate: 'Date settled',
      status: 'Status',
      type: 'Type',
      source: 'Source',
      advisor: 'Advisor',
      organisations: 'Organisations',
      relatedUser: 'Related user',
      advisorIntroducer: 'Advisor / Introducer',
      club: 'Club',
      resetAll: 'Reset all',
      applyFilters: 'Apply filters',
      client: 'Client',
      property: 'Property',
      total: 'Total',
      clientsOnly: 'Show clients only',
      addAdditionalCredit: 'Add additional credit',
      paymentDirection: 'Payment direction',
      payee: 'Payee',
      introducerOrganisation: 'Introducer organisation',
      introducer: 'Introducer',
      lender: 'Lender',
      addOneOffPayment: 'Add/edit one off payment',
      addRecurringPayment: 'Add recurring payment',
      insurer: 'Health and Protection provider',
      noInsurers: 'No Health and Protection providers',
      caseOwner: 'Case owner',
      paymentDirections: {
        credit: 'Credit',
        debit: 'Debit',
      },
      predictedRevenue: {
        title: 'Total predicted revenue',
        feeType: {
          protectionProcFee: 'Health and Protection proc fee',
          mortgageProcFee: 'Mortgage proc fee',
          PREADVICE: 'Pre-advice fee',
          PRERECOMMENDATION: 'Pre-recommendation fee',
          PREAPPLICATION: 'Pre-application fee',
          OFFER: 'Offer fee',
          LEGAL_COMPLETION: 'Legal completion fee',
        },
        commissionFrequency: {
          MONTHLY: 'months',
          ANNUALLY: 'years',
        },
      },

      ledgerPaymentTypes: {
        GeneralInsuranceProcFee: 'GI Procuration Fee',
        MortgageLegalCompletionCombined: 'Completion Fee (Combined)',
        MortgageLegalCompletionFixed: 'Completion Fee',
        MortgageLegalCompletionPercentage: 'Completion Fee',
        MortgageLegalExchangeFixed: 'Exchange Fee (Fixed)',
        MortgageLegalExchangePercentage: 'Exchange Fee (Percentage)',
        MortgageOfferCombined: 'Offer Fee (Combined)',
        MortgageOfferFixed: 'Offer Fee',
        MortgageOfferPercentage: 'Offer Fee',
        MortgageProcFee: 'Mortgage Procuration Fee',
        PreMortgageAdviceCombined: 'Pre-Advice Fee (Combined)',
        PreMortgageAdviceFixed: 'Pre-Advice Fee',
        PreMortgageAdvicePercentage: 'Pre-Advice Fee',
        PreMortgageApplicationCombined: 'Pre-Application Fee (Combined)',
        PreMortgageApplicationFixed: 'Pre-Application Fee',
        PreMortgageApplicationPercentage: 'Pre-Application Fee',
        PreMortgageRecommendationCombined: 'Pre-Recommendation Fee (Combined)',
        PreMortgageRecommendationFixed: 'Pre-Recommendation Fee',
        PreMortgageRecommendationPercentage: 'Pre-Recommendation Fee',
        ProtectionProcFee: 'Health and Protection Procuration Fee',
        LandRegistryReportFee: 'Title Report Fee',
        IntroducerProtectionCommission: 'Introducer Health and Protection Commission',
        AdvisorProtectionCommission: 'Advisor Health and Protection Commission',
        IntroducerMortgageCommission: 'Introducer Mortgage Commission',
        AdvisorMortgageCommission: 'Advisor Mortgage Commission',
        CaseManagerCommission: 'Case Manager Commission',
        ReferrerCommission: 'Referrer Commission',
        AdministratorCommission: 'Administrator Commission',
        LeadQualifierCommission: 'Lead Qualifier Commission',
        WatcherCommission: 'Watcher Commission',
        ReviewerCommission: 'Reviewer Commission',
        Clawback: 'Clawback',
        MortgageReferenceFee: 'Mortgage Reference Fee',
        ValuationFee: 'Valuation Fee',
        RelatedUserCommission: 'Related user commission',
        WillReferralFee: 'Will Referral Fee',
        MasterBrokerReferralFee: 'Master Broker Referral Fee',
        PensionReferralFee: 'Pension Referral Fee',
        OtherReferralFee: 'Other Referral Fee',
        ConveyancingReferralFee: 'Conveyancing Referral Fee',
        AdvisorMortgageTierCommission: 'Advisor Mortgage Tier Commission',
        AdvisorProtectionTierCommission: 'Advisor Protection Tier Commission',
        ClubFee: 'Club Fee',
      },
      ledgerPaymentSourceTypes: {
        Client: 'Clients',
        Advisor: 'Advisors',
        Club: 'Clubs',
        Lender: 'Lenders',
        Introducer: 'Introducers',
        MasterBroker: 'Master brokers',
        Network: 'Networks',
        Packager: 'Packagers',
        LandRegistry: 'Land Registry',
        Insurer: 'Insurer',
        User: 'User',
      },
      outboundSettled: {
        title: 'Outbound settled payments',
      },
      outboundPayments: {
        title: 'Outbound payments due',
      },
      paymentsDue: {
        title: 'Payments due',
        noData: 'You currently have no payments due',
      },
      paymentsReceived: {
        title: 'Payments settled',
        noData: 'You currently have no payments settled',
      },
      actions: {
        markPaid: 'Mark paid',
        writeOff: 'Write off',
        cancel: 'Cancel',
        viewCase: 'View case',
        removePayment: 'Remove payment',
      },
      cancelPayment: {
        cancelPayment: 'Cancel payment',
        selectPayment: 'Select payment',
      },
      popup: {
        amount: 'Amount',
        date: 'Date',
        payee: 'Payee',
        status: 'Status',
        paid: 'Paid',
        cancelled: 'Cancelled',
      },
      subnav: {
        title: 'Accounting',
        paymentsDue: 'Payments due',
        paymentsSettled: 'Payments settled',
        outboundPayments: 'Outbound payments',
        outboundSettled: 'Outbound settled',
      },
      statuses: {
        SETTLED: 'Paid',
        DUE: 'Due',
        WRITTEN_OFF: 'Written off',
        PARTIALLY_PAID: 'Partially paid',
        PARTIALLY_WRITTEN_OFF: 'Partially written off',
        IN_FORCE: 'In force',
        OVERDUE: 'Overdue',
        CLOSE: 'Close',
        COLLECTED: 'Collected',
      },
      totals: {
        amountDue: 'Amount due',
        amountPaid: 'Amount paid',
        totalLedgers: 'Number of payments',
      },
    },
  },
}

export default messagesIntroducers
