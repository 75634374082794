import React, { memo, useCallback, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { UserContext } from '@introducers-contexts'
import { FormattedMessage } from 'react-intl'

import {
  VerificationSourceType,
  VerificationType,
  VerifyDocumentDocument,
  VerifyDocumentMutation,
  VerifyDocumentMutationVariables,
} from '@acre/graphql'
import { Modal } from '@acre/design-system'

import { filterIntroducerClientRecordPerEnv, useEula } from './Eula.helpers'
import EulaForm from './EulaForm'

const Eula = () => {
  const user = useContext(UserContext)
  const { loading, doc, signedEula, setSignedEula } = useEula({
    user,
  })
  // Queries
  const [renderAndStoreDocument, { loading: renderAndSaveDocLoading }] = useMutation<
    VerifyDocumentMutation,
    VerifyDocumentMutationVariables
  >(VerifyDocumentDocument, {
    // if acceptance happened successfully, we continue user flow
    onCompleted: () => setSignedEula(true),
  })

  // props for children
  const headerText = <FormattedMessage id="eula.header" />
  const checkboxText = <FormattedMessage id="eula.confirmation" />
  const confirmationText = <FormattedMessage id="eula.submit" />

  const introducerClientRecord = filterIntroducerClientRecordPerEnv(/*user*/)

  const handleSubmit = useCallback(async () => {
    await renderAndStoreDocument({
      variables: {
        input: {
          document_id: doc?.document_id,
          document_signature: doc?.document_signature,
          client_id: introducerClientRecord?.id,
          verification_type: VerificationType.Printed,
          source: VerificationSourceType.UserOnBehalfOfClient,
        },
      },
    })
  }, [doc])

  if (doc?.data_base64) {
    return (
      <Modal open={!signedEula} showHeading={false} size="fullScreen">
        <EulaForm
          headerText={headerText}
          checkboxText={checkboxText}
          buttonText={confirmationText}
          doc={doc.data_base64}
          loading={renderAndSaveDocLoading || loading}
          onSubmit={handleSubmit}
        />
      </Modal>
    )
  }
  return null
}

export default memo(Eula)
