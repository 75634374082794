import React, { useEffect, useState } from 'react'
import { ApolloClient } from '@apollo/client'
import { ApolloConsumer } from '@apollo/client'
import { FormattedMessage, useIntl } from 'react-intl'

import { AddressSummary, GetAddressLookupListDocument, Maybe } from '@acre/graphql'
import { BUTTON_TYPE_BUTTON } from '@acre/design-system'

import { BlueText, GreyText, NoResultLi, SearchResultButton, SearchWrapper } from './AddressLookup.styles'

type AddressLookupType = Maybe<Pick<AddressSummary, 'summary' | 'id' | 'count' | 'location'>[]>

type Props = {
  searchFragment: string
  onClick: (client: ApolloClient<any>, searchFragment: string, id: string) => void | Promise<void>
  addresses?: AddressLookupType
}

const AddressDropDown = ({ searchFragment, onClick, addresses }: Props) => {
  const [addressSearchResult, setAddressSearchResult] = useState<AddressLookupType>(addresses ? addresses : null)
  const [hasResults, setHasResults] = useState<boolean>(!addresses || addresses.length === 0)

  const intl = useIntl()
  const _formatMessage = (id: string): string => intl.formatMessage({ id })

  // checks if address props updated and sets the state if it did
  useEffect(() => {
    if (addresses && addressSearchResult !== addresses) {
      setAddressSearchResult(addresses)
      setHasResults(addresses.length === 0)
    }
  }, [addresses])

  const handleAddressListLookup = async (client: ApolloClient<unknown>, searchFragment: string, id: string) => {
    const { data } = await client.query({
      query: GetAddressLookupListDocument,
      variables: { searchFragment, pathFilter: id },
    })
    setAddressSearchResult(data.addressLookupList)
  }

  // checks if address group was returned to keep narrowing results
  // until single result can be returned to parent
  const narrowSearchResults = (count: number, client: ApolloClient<any>, searchFragment: string, id: string) => {
    if (count === 0) {
      return setHasResults(true)
    }
    if (count === 1) {
      return onClick(client, searchFragment, id)
    }
    return handleAddressListLookup(client, searchFragment, id)
  }

  const highlightedMatch = (summary: string) =>
    summary.replace(new RegExp(searchFragment, 'gi'), (match) => `<strong>${match}</strong>`)

  const createMarkup = (stringifiedMarkup: string) => {
    return {
      __html: stringifiedMarkup,
    }
  }

  return (
    <SearchWrapper>
      {!hasResults && addressSearchResult ? (
        addressSearchResult.map(({ id, summary, count, location }) => {
          const nestedAddressesNum = `(${count} ${_formatMessage('common.addresses')})`
          const addressFistLines = createMarkup(highlightedMatch(summary))
          const addressTownAndDetails = ` ${location} `

          return (
            <li key={id}>
              <ApolloConsumer>
                {(client) => (
                  <SearchResultButton
                    type={BUTTON_TYPE_BUTTON}
                    onClick={() => narrowSearchResults(count, client, searchFragment, id)}
                  >
                    <span dangerouslySetInnerHTML={addressFistLines} />
                    <GreyText>{addressTownAndDetails}</GreyText>
                    {count > 1 && <BlueText>{nestedAddressesNum}</BlueText>}
                  </SearchResultButton>
                )}
              </ApolloConsumer>
            </li>
          )
        })
      ) : (
        <NoResultLi>
          <FormattedMessage id="common.addressLookup.noResults" />
        </NoResultLi>
      )}
    </SearchWrapper>
  )
}

export default AddressDropDown
