import React, { ReactNode, useRef, useState } from 'react'
import { ApolloClient, useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

import { testHandle, useClickOutsideListener } from '@acre/utils'
import { GetAddressLookupDocument, GetAddressLookupSummaryDocument, GetAddressLookupSummaryQuery } from '@acre/graphql'
import { AddressAndFieldUpdates } from '@acre/graphql'
import {
  Button,
  BUTTON_SECONDARY,
  BUTTON_TYPE_BUTTON,
  CenteredLoadingSpinner,
  HELPER_TYPE_ERROR,
  HelperText,
  Label,
  TextInput,
  Variant,
} from '@acre/design-system'

import AddressDropDown from './AddressLookupDropDown'
import { Results, RowWrapper, SearchWrapper } from './AddressLookup.styles'

type Props = {
  onClick: () => void
  onGetAddress: (address: AddressAndFieldUpdates) => void
  label?: string
  variant?: Variant
  disabled?: boolean
  isMissing?: boolean
  secondaryText?: ReactNode
}

const minSearchChar = 3

const Lookup = ({ onClick, onGetAddress, label, variant = 'default', disabled, isMissing, secondaryText }: Props) => {
  const intl = useIntl()
  const [searchFragment, setSearchFragment] = useState<string>('')
  const [showDropDown, setShowDropDown] = useState<boolean>(false)
  const addressDropDownRef = useRef<HTMLDivElement>(null)

  const _formatMessage = (id: string): string => intl.formatMessage({ id })
  const lookupLabel = label || _formatMessage('common.addressLookup.lookupLabel')

  const handleSearchFragmentChange = (value: string) => {
    const stringWithoutSpecialCharacters = value.replace(/[^a-z\d\s]+/gi, '')
    setShowDropDown(stringWithoutSpecialCharacters.trim().length >= minSearchChar)
    setSearchFragment(stringWithoutSpecialCharacters)
  }

  const handleAddressLookup = async (client: ApolloClient<unknown>, searchFragment: string, id: string) => {
    const { data } = await client.query({
      query: GetAddressLookupDocument,
      variables: { searchFragment, id },
    })
    onGetAddress(data.addressLookup[0])
  }

  useClickOutsideListener(
    addressDropDownRef,
    () => {
      setShowDropDown(false)
      setSearchFragment('')
    },
    showDropDown,
  )

  const { data, error, loading } = useQuery<GetAddressLookupSummaryQuery>(GetAddressLookupSummaryDocument, {
    skip: searchFragment.length < minSearchChar,
    variables: { searchFragment },
  })

  return (
    <>
      <SearchWrapper>
        <RowWrapper variant={variant}>
          <Label variant={variant} text={lookupLabel} htmlFor="SearchFragment" secondaryText={secondaryText} />
          <Box display="flex" width="100%" alignItems="center">
            <TextInput
              id="SearchFragment"
              value={searchFragment}
              placeholder="e.g. W8 5EH"
              onChange={(evt) => handleSearchFragmentChange(evt.target.value)}
              variant={variant}
              disabled={disabled}
              name="AddressLookupFragment"
            />
            <Button
              type={BUTTON_TYPE_BUTTON}
              id="AddressLookupAction"
              buttonStyle={BUTTON_SECONDARY}
              onClick={onClick}
              variant="compact"
            >
              <FormattedMessage id="common.addressLookup.manualButton" />
            </Button>
          </Box>
        </RowWrapper>
        {loading && (
          <Results isVisible style={{ padding: 10 }} variant={variant}>
            <CenteredLoadingSpinner />
          </Results>
        )}
        {error && (
          <>
            {Error!} {error.message}
          </>
        )}
        {data && data.addressLookupSummary && (
          <div ref={addressDropDownRef}>
            <Results isVisible={showDropDown} data-testid={testHandle('AddressLookupResults')} variant={variant}>
              <AddressDropDown
                addresses={data.addressLookupSummary}
                searchFragment={searchFragment}
                onClick={handleAddressLookup}
              />
            </Results>
          </div>
        )}
      </SearchWrapper>
      {isMissing && (
        <HelperText
          id="AddressLookupHelper"
          message={_formatMessage('errors.missingFieldRequired')}
          textType={HELPER_TYPE_ERROR}
          variant={variant}
        />
      )}
    </>
  )
}

export default Lookup
