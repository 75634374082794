import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { SchemaLink } from '@apollo/client/link/schema'
import { omit } from 'lodash'

import { authRedirectErrorLink, graphqlErrorLink, lutherErrorLink } from '@acre/utils'
import { QueryReferredCaseListArgs, schema } from '@acre/graphql'

import typePolicies from './typePolicies'

const initApollo = () => {
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: ApolloLink.from([
      onError(lutherErrorLink),
      onError((error: ErrorResponse) => authRedirectErrorLink(error, client)),
      onError(graphqlErrorLink),
      new SchemaLink({
        schema,
      }),
    ]),
    cache: new InMemoryCache({
      addTypename: true,
      // This util allows us to specify something other than 'id' for GraphQL's cache mechanism
      typePolicies: {
        ...typePolicies,
        Document: { keyFields: ['document_id'] },
        ProtectionProduct: { keyFields: ['protection_id'] },
        CommissionStructure: { keyFields: ['commission_structure_id'] },
        Query: {
          fields: {
            ...typePolicies.Query.fields,
            referredCaseList: {
              keyArgs: (params?: { filter?: QueryReferredCaseListArgs } | null) => {
                if (params && params.filter) {
                  // removing pagination bookmark so fetchmore functions match
                  return JSON.stringify(omit(params.filter, 'bookmark'))
                }
                return '{}'
              },
            },
          },
        },
        GetMiCaseListResponse: {
          fields: {
            cases: {
              merge(existing = [], incoming) {
                return [...existing, ...incoming]
              },
            },
          },
        },
      },
    }),
  })

  return client
}

export default initApollo
