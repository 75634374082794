import React from 'react'
import Cookies from 'js-cookie'
import { Navigate } from 'react-router-dom'

export const RedirectOnRoot = () => {
  const redirect = Cookies.get('login_redirect')

  if (redirect) {
    Cookies.remove('login_redirect')
  }

  return <Navigate to={redirect || '/dashboard'} replace />
}
