import NotFountIcon from './notFound.svg?react'
import React, { useCallback } from 'react'
import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useFormatMessage } from '@acre/utils'
import { Button, H1, PageLayout, Spacer } from '@acre/design-system'

const ErrorPageContents = styled('div')`
  max-width: 600px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
`

const NotFound = () => {
  const formatMessage = useFormatMessage()

  const navigate = useNavigate()

  const handleOnBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <PageLayout>
      <ErrorPageContents>
        <NotFountIcon />

        <Spacer top={1} bottom={1}>
          <H1>{formatMessage('errorPage.text')}</H1>
        </Spacer>

        <Button type="button" id="go-back" onClick={handleOnBack}>
          {formatMessage('errorPage.button')}
        </Button>
      </ErrorPageContents>
    </PageLayout>
  )
}

export default NotFound
