import * as assets from '../../assets'

export enum IconTypes {
  AcreLogo,
  AcreLogoSmall,
  AcreLogoWhite,
  AcreSquareLogo,
  AcreWordMark,
  AcreWordMarkLight,
  ArrowBack,
  ArrowBackPortal,
  ArrowDown,
  ArrowForward,
  ArrowLeft,
  ArrowLeftWhite,
  ArrowRight,
  ArrowUp,
  GreenArrow,
  BlueArrow,
  BlueEye,
  PurpleArrow,
  BadgeEmpty,
  BadgeError,
  BadgeSuccess,
  BadgeWarning,
  BankCard,
  BannerSuccess,
  BannerWarning,
  Bell,
  BlackArrowBack,
  Briefcase,
  BrokerIcon,
  Burger,
  Camera,
  CameraPortal,
  Case,
  Cases,
  Clients,
  Close,
  Company,
  Dashboard,
  DashboardDocument,
  DashboardMortgage,
  DashboardUser,
  Document,
  Documents,
  DropdownActive,
  Employment,
  EyeClosed,
  EyeOpen,
  Flask,
  Folder,
  Home,
  House,
  HouseGBP,
  HTML,
  KeyPadlock,
  MagnifyingGlass,
  MintStacks,
  Minus,
  Misc,
  MultiStorey,
  Notifications,
  OrangeUsers,
  Overflow,
  Padlock,
  Page,
  PDF,
  Plus,
  Profile,
  Protection,
  PurpleFolders,
  Search,
  Settings,
  ShieldPound,
  Show,
  SideArrow,
  Sourcing,
  Tick,
  Times,
  Todo,
  Tooltip,
  UserIcon,
  Users,
  VerifyDoneIcon,
  VerifyIcon,
  VerifySelfieIcon,
  FilledDocument,
  FilledDocumentPortal,
  SmallFile,
  SmallFilePortal,
  BlackFile,
  BadgeLead,
  BarChart,
  GreenExclamationPoint,
  BlueVerifyId,
  OnfidoPassed,
  OnfidoFailed,
  HomeIcon,
  Exclamation,
}

export const Icons: Record<
  IconTypes,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
> = {
  [IconTypes.AcreLogo]: assets.AcreLogo,
  [IconTypes.AcreLogoSmall]: assets.AcreLogoSmall,
  [IconTypes.AcreLogoWhite]: assets.AcreLogoWhite,
  [IconTypes.AcreSquareLogo]: assets.AcreSquareLogo,
  [IconTypes.AcreWordMark]: assets.AcreWordMark,
  [IconTypes.AcreWordMarkLight]: assets.AcreWordMarkLight,
  [IconTypes.ArrowBack]: assets.BackArrow,
  [IconTypes.ArrowBackPortal]: assets.BackArrowPortal,
  [IconTypes.ArrowDown]: assets.ArrowDown,
  [IconTypes.ArrowForward]: assets.ArrowForwardIcon,
  [IconTypes.ArrowLeft]: assets.ArrowLeft,
  [IconTypes.ArrowRight]: assets.ArrowRightIcon,
  [IconTypes.ArrowUp]: assets.ArrowUp,
  [IconTypes.GreenArrow]: assets.GreenArrow,
  [IconTypes.BlueArrow]: assets.BlueArrow,
  [IconTypes.PurpleArrow]: assets.PurpleArrow,
  [IconTypes.BadgeEmpty]: assets.BadgeEmpty,
  [IconTypes.BadgeError]: assets.BadgeError,
  [IconTypes.BadgeSuccess]: assets.BadgeSuccess,
  [IconTypes.BadgeWarning]: assets.BadgeWarning,
  [IconTypes.BankCard]: assets.BankCard,
  [IconTypes.BannerSuccess]: assets.BannerSuccess,
  [IconTypes.BannerWarning]: assets.BannerWarning,
  [IconTypes.Bell]: assets.Bell,
  [IconTypes.BlackArrowBack]: assets.BlackArrowBack,
  [IconTypes.ArrowLeftWhite]: assets.ArrowLeftWhite,
  [IconTypes.Briefcase]: assets.Briefcase,
  [IconTypes.BrokerIcon]: assets.BrokerIcon,
  [IconTypes.Burger]: assets.Burger,
  [IconTypes.Camera]: assets.CameraIcon,
  [IconTypes.CameraPortal]: assets.CameraIconPortal,
  [IconTypes.Case]: assets.Case,
  [IconTypes.Cases]: assets.Cases,
  [IconTypes.Clients]: assets.Clients,
  [IconTypes.Close]: assets.Close,
  [IconTypes.Company]: assets.Company,
  [IconTypes.Dashboard]: assets.Dashboard,
  [IconTypes.DashboardDocument]: assets.DashboardDocument,
  [IconTypes.DashboardMortgage]: assets.DashboardMortgage,
  [IconTypes.DashboardUser]: assets.DashboardUser,
  [IconTypes.Document]: assets.Document,
  [IconTypes.Documents]: assets.Documents,
  [IconTypes.DropdownActive]: assets.DropdownIcon,
  [IconTypes.Employment]: assets.Employment,
  [IconTypes.EyeClosed]: assets.EyeClosed,
  [IconTypes.EyeOpen]: assets.EyeOpen,
  [IconTypes.FilledDocument]: assets.FilledDocument,
  [IconTypes.FilledDocumentPortal]: assets.FilledDocumentPortal,
  [IconTypes.Flask]: assets.Flask,
  [IconTypes.Folder]: assets.Folder,
  [IconTypes.Home]: assets.Home,
  [IconTypes.House]: assets.House,
  [IconTypes.HouseGBP]: assets.HouseGBP,
  [IconTypes.HTML]: assets.HTML,
  [IconTypes.KeyPadlock]: assets.KeyPadlock,
  [IconTypes.MagnifyingGlass]: assets.MagnifyingGlass,
  [IconTypes.MintStacks]: assets.MintStacks,
  [IconTypes.Minus]: assets.Minus,
  [IconTypes.Misc]: assets.Misc,
  [IconTypes.MultiStorey]: assets.MultiStorey,
  [IconTypes.Notifications]: assets.Notifications,
  [IconTypes.OrangeUsers]: assets.OrangeUsers,
  [IconTypes.Overflow]: assets.Overflow,
  [IconTypes.Padlock]: assets.Padlock,
  [IconTypes.Page]: assets.Page,
  [IconTypes.PDF]: assets.PDF,
  [IconTypes.Plus]: assets.Plus,
  [IconTypes.Profile]: assets.Profile,
  [IconTypes.Protection]: assets.Protection,
  [IconTypes.PurpleFolders]: assets.PurpleFolders,
  [IconTypes.Search]: assets.Search,
  [IconTypes.Settings]: assets.Settings,
  [IconTypes.ShieldPound]: assets.ShieldPound,
  [IconTypes.Show]: assets.Show,
  [IconTypes.SideArrow]: assets.SideArrow,
  [IconTypes.Sourcing]: assets.Sourcing,
  [IconTypes.Tick]: assets.Tick,
  [IconTypes.Todo]: assets.Todo,
  [IconTypes.Tooltip]: assets.TooltipQuestionMark,
  [IconTypes.UserIcon]: assets.UserIcon,
  [IconTypes.Users]: assets.Users,
  [IconTypes.VerifyDoneIcon]: assets.VerifyDoneIcon,
  [IconTypes.VerifyIcon]: assets.VerifyIcon,
  [IconTypes.VerifySelfieIcon]: assets.VerifySelfieIcon,
  [IconTypes.SmallFile]: assets.SmallFile,
  [IconTypes.SmallFilePortal]: assets.SmallFilePortal,
  [IconTypes.BlackFile]: assets.BlackFile,
  [IconTypes.BadgeLead]: assets.BadgeLead,
  [IconTypes.BarChart]: assets.BarChart,
  [IconTypes.Times]: assets.Times,
  [IconTypes.GreenExclamationPoint]: assets.GreenExclamationPoint,
  [IconTypes.BlueVerifyId]: assets.BlueVerifyId,
  [IconTypes.OnfidoPassed]: assets.OnfidoPassed,
  [IconTypes.OnfidoFailed]: assets.OnfidoFailed,
  [IconTypes.BlueEye]: assets.BlueEye,
  [IconTypes.HomeIcon]: assets.HomeIcon,
  [IconTypes.Exclamation]: assets.Exclamation,
}
