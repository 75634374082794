import React, { ReactNode, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import { getData } from 'country-list'
import { get } from 'lodash'
import { Field } from 'react-final-form'
import { useIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'

import { AddressAndFieldUpdates } from '@acre/graphql'
import {
  Button,
  BUTTON_SECONDARY,
  BUTTON_TYPE_BUTTON,
  DropdownOption,
  HELPER_TYPE_ERROR,
  HelperText,
  Label,
  SingleSearchSelect,
  TextInput,
  Variant,
} from '@acre/design-system'

import { InputWrapper, RowWrapper, SingleSelectorContainer } from './AddressLookup.styles'

type OnChangeKey = 'line_1' | 'line_2' | 'line_3' | 'town' | 'postcode' | 'country'

type Props = {
  address: AddressAndFieldUpdates
  onChange: (address: AddressAndFieldUpdates) => void
  onClick: () => void
  variant?: Variant
  errors?: { [key: string]: { message: string } }
  fieldPrefix?: string
  disabled?: boolean
  address1IsMissing?: boolean
  posttownIsMissing?: boolean
  postcodeIsMissing?: boolean
  secondaryText?: ReactNode
}

const messagePrefix = 'common.addressLookup'

const AddressManual = ({
  address,
  address: { country },
  onChange,
  onClick,
  errors,
  variant = 'default',
  fieldPrefix = '',
  disabled,
  address1IsMissing,
  postcodeIsMissing,
  posttownIsMissing,
  secondaryText,
}: Props) => {
  const intl = useIntl()
  const formatMessage = (id: string): string => intl.formatMessage({ id })
  const { line_1, line_2, line_3, town, postcode } = address || {}
  const handleChange = (value: string, key: OnChangeKey) => {
    const newAddress = {
      ...address,
      [key]: value,
    }
    onChange(newAddress)
  }

  const getError = (key: string) => {
    const message = errors && get(errors, key) ? formatMessage(get(errors, key).message) : ''
    const error = errors && !!get(errors, key)
    return { message, error }
  }

  const countries = useMemo<DropdownOption[]>(
    () =>
      getData()
        .map((value) => {
          return {
            label: value.name,
            value: value.code,
          } as DropdownOption
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    [],
  )

  useEffect(() => {
    if (!country) {
      handleChange('GB', 'country')
    }
  }, [])

  return (
    <>
      <Field name="line_1">
        {({ input, meta }) => (
          <>
            <RowWrapper variant={variant}>
              <Label
                variant={variant}
                text={formatMessage(`${messagePrefix}.line1`)}
                htmlFor="SearchFragment"
                secondaryText={secondaryText}
              />
              <Box display="flex" width="100%" alignItems="center">
                <TextInput
                  {...input}
                  id="Address1"
                  value={line_1 || input.value}
                  onChange={(evt) => handleChange(evt.target.value, 'line_1')}
                  variant={variant}
                  error={meta.touched && getError(`${fieldPrefix}address.address1`).error}
                  disabled={disabled}
                />
                <Button
                  type={BUTTON_TYPE_BUTTON}
                  id="AddressLookupAction"
                  buttonStyle={BUTTON_SECONDARY}
                  onClick={onClick}
                  variant="compact"
                  disabled={disabled}
                >
                  <FormattedMessage id={`${messagePrefix}.lookupButton`} />
                </Button>
              </Box>
            </RowWrapper>

            {/* Use these rather than the input's build-in message and error props because the button messes with the alignment */}
            {meta.touched && getError(`${fieldPrefix}address.address1`).message && (
              <Box width="100%">
                <HelperText
                  {...getError(`${fieldPrefix}address.address1`)}
                  variant={variant}
                  textType="error"
                  id="Address1Helper"
                />
              </Box>
            )}
            {address1IsMissing && (
              <Box width="100%">
                <HelperText
                  id="Address1IsMissing"
                  message={formatMessage('errors.missingFieldRequired')}
                  textType={HELPER_TYPE_ERROR}
                  variant={variant}
                />
              </Box>
            )}
          </>
        )}
      </Field>

      <InputWrapper variant={variant}>
        <Field name="line_2">
          {({ input, meta }) => {
            const address2Errors = meta.touched && getError(`${fieldPrefix}address.address2`)
            return (
              <TextInput
                {...input}
                id="Address2"
                value={line_2 || ''}
                label={formatMessage(`${messagePrefix}.line2`)}
                onChange={(evt) => handleChange(evt.target.value, 'line_2')}
                variant={variant}
                disabled={disabled}
                {...address2Errors}
              />
            )
          }}
        </Field>
        <TextInput
          id="Address3"
          value={line_3 || ''}
          label={formatMessage(`${messagePrefix}.line3`)}
          onChange={(evt) => handleChange(evt.target.value, 'line_3')}
          variant={variant}
          disabled={disabled}
        />
      </InputWrapper>

      <InputWrapper variant={variant}>
        <Field name="town">
          {({ input, meta }) => {
            const townErrors = meta.touched && getError(`${fieldPrefix}address.posttown`)
            return (
              <TextInput
                {...input}
                id="Town"
                value={town || input.value}
                label={formatMessage(`${messagePrefix}.town`)}
                onChange={(evt) => handleChange(evt.target.value, 'town')}
                variant={variant}
                disabled={disabled}
                isMissing={posttownIsMissing}
                {...townErrors}
              />
            )
          }}
        </Field>

        <Field name="postcode">
          {({ input, meta }) => {
            const postCodeErrors = meta.touched && getError(`${fieldPrefix}address.postcode`)
            return (
              <TextInput
                {...input}
                id="Postcode"
                value={postcode || input.value}
                label={formatMessage(`${messagePrefix}.postcode`)}
                onChange={(evt) => handleChange(evt.target.value, 'postcode')}
                variant={variant}
                disabled={disabled}
                isMissing={postcodeIsMissing}
                {...postCodeErrors}
              />
            )
          }}
        </Field>
      </InputWrapper>
      {
        /* NB This is filtered so the property form doesn't get the country selector */
        variant != 'compact' ? (
          <SingleSelectorContainer>
            <SingleSearchSelect
              id="Country"
              value={country || ''}
              label={formatMessage(`${messagePrefix}.country`)}
              options={countries}
              variant={variant}
              disabled={disabled}
              placeholder={formatMessage('generic.typeToSearch')}
              onChange={(evt) => handleChange(evt, 'country')}
            />
          </SingleSelectorContainer>
        ) : (
          ''
        )
      }
    </>
  )
}

export default AddressManual
