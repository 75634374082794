import { GlobalStyles } from '@mui/material'

export const GlobalStyle = () => (
  <GlobalStyles
    styles={(theme) => `
      html,
      body {
        box-sizing: border-box;
        font-family: ${theme.typography.fontFamily};
        font-size: 16px;
        font-weight: 400;
        color: ${theme.colours.base};
        background: ${theme.colours.background};
      }

      html {
        overflow-y: scroll;
        scroll-behavior: smooth;
      }

      html,
      body,
      #app,
      #root,
      .router {
        height: 100%;
        width: 100%;
        overflow: auto;
      }

      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }

      body,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      ol,
      ul {
        margin: 0;
        padding: 0;
      }

      ol,
      ul {
        list-style: none;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      a {
        color: ${theme.colours.primary};
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      button {
        font-size: 16px;
        font-family: ${theme.typography.fontFamily};
        background: transparent;
        cursor: pointer;
        border: 0 none;
      }

      input {
        font-family: ${theme.typography.fontFamily};
      }

      textarea {
        font-family: ${theme.typography.fontFamily};
      }

      legend {
        padding:0
      }

      /* We dont want the tickers */
      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }
      /* End - We dont want the tickers */
`}
  />
)
