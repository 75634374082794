import { styled } from '@mui/material'

export const PdfDocWrapper = styled('div')`
  height: 400px;
  overflow: scroll;
`

export const UnsetMarginWrapper = styled('div')<{
  margin: number
}>(({ theme, margin }) => ({
  marginLeft: `-${theme.spacing(margin)}`,
  marginRight: `-${theme.spacing(margin)}`,
}))
