import * as yup from 'yup'

import { ERROR_INPUT_MUST_BE_LETTER, ERROR_REQUIRED, unwrapErrors } from '@acre/utils'
import { ClientInput } from '@acre/graphql'

export const clientValidationSchema = yup.object<Partial<ClientInput>>().shape({
  first_name: yup
    .string()
    .matches(/^[a-zA-Z-'.\s]+$/, ERROR_INPUT_MUST_BE_LETTER)
    .required(ERROR_REQUIRED),
  last_name: yup
    .string()
    .matches(/^[a-zA-Z-'.\s]+$/, ERROR_INPUT_MUST_BE_LETTER)
    .required(ERROR_REQUIRED),
})

export const validateClient = unwrapErrors(clientValidationSchema)
