import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js'

const isPhoneNumValid = (phoneNumber: string): boolean => {
  if (!phoneNumber) {
    return false
  }

  const phoneNumberValidator: PhoneNumber | undefined = parsePhoneNumberFromString(`${phoneNumber}`, 'GB')
  //libphonenumber-js trims non-digits from the end of numbers before validating.  We therefore need to check if this has happened in order to avoid false positives.
  const noExtraChars = phoneNumberValidator?.number.endsWith(phoneNumber.trim().slice(-1))

  if (phoneNumber.charAt(0) === '+' && phoneNumber.replace(' ', '').length > 13) {
    return false
  }

  return !!(phoneNumberValidator && phoneNumberValidator.isValid() && noExtraChars)
}

export default isPhoneNumValid
