import React, { useMemo } from 'react'
import { ApolloProvider as ApolloHooksProvider, ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@mui/material'
import { IntlProvider } from 'react-intl'

import { ErrorProvider, flattenMessages, getMessages, Locales } from '@acre/utils'
import { Alert, DefaultErrorBoundary, PrettyErrorBoundary, theme } from '@acre/design-system'

import initApollo from './graphql/initApollo'
import messages from './intl/messagesIntroducers'
import { GlobalStyle } from './index.styles'

const flattenedMessages = flattenMessages(getMessages(messages, Locales.GB))

type Props = {
  children: JSX.Element
}

const AppProviders = ({ children }: Props) => {
  const client = useMemo(initApollo, [])
  return (
    <DefaultErrorBoundary>
      <ThemeProvider theme={theme}>
        <IntlProvider locale={Locales.GB} messages={flattenedMessages}>
          <ApolloProvider client={client}>
            <ApolloHooksProvider client={client}>
              <ErrorProvider alertSlot={Alert}>
                <GlobalStyle />
                <PrettyErrorBoundary>{children}</PrettyErrorBoundary>
              </ErrorProvider>
            </ApolloHooksProvider>
          </ApolloProvider>
        </IntlProvider>
      </ThemeProvider>
    </DefaultErrorBoundary>
  )
}
export default AppProviders
