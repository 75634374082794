import { createContext, useContext } from 'react'

import { GetCurrentUserQuery, Maybe } from '@acre/graphql'

export const UserContext = createContext<Maybe<GetCurrentUserQuery['currentUser']>>(null)

export default UserContext
export const UserConsumer = UserContext.Consumer
export const UserProvider = UserContext.Provider

export const useUserContext = () => useContext(UserContext)
