import React, { ReactNode, useEffect } from 'react'
import { useUserContext } from '@introducers-contexts'
import Cookies from 'js-cookie'
import { useLocation, useNavigate } from 'react-router-dom'

import { FullPageLoadingSpinner } from '@acre/design-system'

type Props = {
  children: ReactNode
}

const RequireAuth = ({ children }: Props) => {
  const user = useUserContext()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!user) {
      // set a cookie with the current URL and redirect
      // to the login page
      Cookies.set('login_redirect', location.pathname)
      navigate('/login')
    }
  }, [location.pathname, navigate, user])

  if (!user) {
    return <FullPageLoadingSpinner />
  }

  return <>{children}</>
}

export default RequireAuth
