import { History } from 'history'
import { MessageDescriptor } from 'react-intl'

import { FileType } from '@acre/graphql'

export enum MimeTypes {
  Html = 'text/html',
  Pdf = 'application/pdf',
  Jpg = 'image/jpeg',
  Png = 'image/png',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  Mp4 = 'video/mp4',
  Mp3 = 'audio/mpeg',
  Wav = 'audio/wav',
  Tiff = 'image/tiff',
  Eml = 'message/rfc822',
  Mbox = 'application/mbox',
  Msg = 'application/vnd.ms-outlook',
}

export const supportedFileTypes = `${Object.values(FileType)
  .filter((fileType) => fileType !== FileType.InvalidFileType)
  .join(', ')}`

export type RouterHistory = {
  goBack: () => any
  push: (args: string) => void
}

export type ReadonlyBrowserHistory = Readonly<History>

// We only support 'en-GB' at the moment
// in the future we will want a white list
// const currentLocale =
//   (navigator.languages && navigator.languages[0]) ||
//   navigator.language ||
//   navigator.userLanguage ||
//   Locales.GB
export enum Locales {
  GB = 'en-GB',
}

export const allowedInputNumberKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
  'ArrowDown',
  'ArrowUp',
  'ArrowLeft',
  'ArrowRight',
  'Backspace',
  'Delete',
  'Tab',
]

export const allowedNegativeInputNumberKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '-',
  '.',
  'ArrowDown',
  'ArrowUp',
  'ArrowLeft',
  'ArrowRight',
  'Backspace',
  'Delete',
  'Tab',
]

/**
 * Object with any or passed values
 */
export type ExactObject<T = any> = { [key: string]: T }

export type FormatMessage = (
  id: MessageDescriptor['id'],
  values?: Record<string, string | number | boolean | Date | null | undefined> | undefined,
) => string

export type ToPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type OmitAndToPartial<T, OmitKeys extends keyof T, PartialKeys extends keyof T> = Omit<
  T,
  OmitKeys | PartialKeys
> &
  Partial<Pick<T, PartialKeys>>
