import { RedirectOnRoot } from '@introducers-utils'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import { lazyRoute } from '@acre/utils'

import NotFound from '../pages/NotFound'
import RequireAuth from '../RequireAuth'
import Root from '../Root'

const browserRouter = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: (
          <RequireAuth>
            <RedirectOnRoot />
          </RequireAuth>
        ),
      },

      { path: '*', element: <Navigate to="/" replace /> },
      { path: 'login', lazy: lazyRoute(() => import('./lazy/Login')) },
      { path: 'dashboard', lazy: lazyRoute(() => import('./lazy/Dashboard')) },

      {
        path: 'reports/:id/*',
        lazy: lazyRoute(() => import('./lazy/Reports')),
        children: [
          { path: 'caseStatusWaterfall', lazy: lazyRoute(() => import('./lazy/ReportsCaseWaterfall')) },
          { path: '*', element: <Navigate to="caseStatusWaterfall" replace /> },
        ],
      },
    ],
  },
])

const Router = () => {
  return <RouterProvider router={browserRouter} />
}

export default Router
