import { FieldReadFunction } from '@apollo/client'

import { CaseReportsColumns, formatAsCurrency, ReportsCaseWaterfallIntroducerResponse } from '@acre/graphql'

import { defaultValue } from './helpers'

const read: FieldReadFunction<CaseReportsColumns> = (_, { readField, variables }) => {
  const reportsCaseWaterfallResponse = readField<ReportsCaseWaterfallIntroducerResponse>({
    fieldName: 'reportsCaseWaterfallIntroducer',
    args: variables?.body ? { body: variables.body } : {},
  })

  const columnValues = {
    LEAD: defaultValue,
    PRE_RECOMMENDATION: defaultValue,
    PRE_APPLICATION: defaultValue,
    APPLICATION_SUBMITTED: defaultValue,
    AWAITING_VALUATION: defaultValue,
    AWAITING_OFFER: defaultValue,
    EXCHANGE: defaultValue,
    OFFER_RECEIVED: defaultValue,
    COMPLETE: defaultValue,
  } as Partial<CaseReportsColumns>

  reportsCaseWaterfallResponse?.totals?.forEach((total) => {
    if (total.case_status) {
      columnValues[total.case_status as keyof CaseReportsColumns] = {
        value: formatAsCurrency(total?.value || 0),
        count: total.count || 0,
      }
    }
  })

  return columnValues as CaseReportsColumns
}

export default {
  read,
}
