import { Box, styled, Theme } from '@mui/material'

// Common
export type ModalTransformation = 'globalSearch' | undefined
const closeButtonShared = (theme?: Theme) => `
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  &:hover {
    color: ${theme?.colours?.primary ?? 'black'};
    & svg path {
      color: ${theme?.colours?.primary ?? 'darkgrey'};
      stroke: ${theme?.colours?.primary ?? 'darkgrey'};
    }
  }
`

const modalBodyShared = (theme: Theme) => `
  text-align: left;
  z-index: ${theme.display.modalZIndex};
  background: ${theme.colours.foreground};
`

// Modal
export const ModalOverlay = styled('div')(
  ({ theme }) => `
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${theme.display.modalBackdropZIndex};
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`,
)

export const CloseButtonSmall = styled('button')(
  ({ theme }) => `
  ${closeButtonShared(theme)}
  & > svg {
    width: 20px;
  }
`,
)

export const CloseButtonSmaller = styled('button')(
  ({ theme }) => `
  ${closeButtonShared(theme)}
  & > svg {
    width: 16px;
  }
`,
)

type ModalInnerContainerProps = {
  centered: Boolean
  size: keyof Theme['wrappers']
  fixedHeight?: Boolean
}

const fixedHeightStyles = () => `
  height: 85%;
  max-height: 85%;
`

export const ModalInnerContainer = styled('dialog')<ModalInnerContainerProps>(
  ({ theme, size, fixedHeight }) => `
  background-color: transparent;
  border-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  outline: none;
  text-align: center;
  box-sizing: border-box;
  height: ${(size == 'fullScreen' && '100%') || (size == 'extraLarge' && '80%') || 'auto'};
  max-height: ${size !== 'fullScreen' && '80%'};
  max-width: ${size === 'fullScreen' ? '100%' : theme.wrappers[size]};
  width: 100%;
  border-radius: ${theme.display.borderRadius};
  padding: 0;
  ${fixedHeight && fixedHeightStyles()}
`,
)

export interface ModalBodyType {
  hideOverflow?: boolean
  hasFooter?: boolean
  size: string
  showHeading?: boolean
  padding?: boolean
  fixedHeight?: Boolean
}

export const ModalBody = styled('div')<ModalBodyType>(
  ({ theme, padding, showHeading, hideOverflow = true, hasFooter, fixedHeight }) => `
  text-align: left;
  z-index: ${theme.display.modalZIndex};
  background: ${theme.colours.foreground};
  padding: ${padding ? theme.display.modalPadding : '0px'};
  border-bottom-left-radius: ${theme.display.borderRadius};
  border-bottom-right-radius: ${theme.display.borderRadius};
  border-top-left-radius: ${!showHeading ? theme.display.borderRadius : 0};
  color: ${theme.colours.base};
  border-top-right-radius: ${!showHeading ? theme.display.borderRadius : 0};
  width: 100%;

  &.modal-overflow {
    overflow-y: visible !important;
  }

  &.modal-scroll {
    overflow-y: scroll;
  }

  &.modal-padding {
    padding: ${theme.display.modalPaddingSmaller};
  }

  &.modal-reduced-padding {
    padding: ${
      padding
        ? `${theme.display.modalPaddingSmallest} ${theme.display.modalPaddingSmallest} 0 ${theme.display.modalPaddingSmallest}`
        : '0px'
    };
  }

  ${hideOverflow && 'overflow-y: auto'};

  ${
    hasFooter
      ? `
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
  `
      : ''
  }
  ${fixedHeight && fixedHeightStyles()}
`,
)

// ModalFullscreen
export const ModalFullscreenContainer = styled(Box, {
  name: 'ModalFullscreen',
  slot: 'Root',
  overridesResolver: (styles) => styles.root,
})<{
  colour?: 'dark' | 'light'
}>(
  ({ theme, colour }) => `
  z-index: ${theme.display.fullPageModalZIndex};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${colour === 'dark' ? theme.colours.background : theme.colours.foreground};
  padding-bottom: ${theme.spacing(8)};
  padding-top: ${theme.spacing(8)};
`,
)

export const Heading = styled('div', {
  name: 'ModalFullscreen',
  slot: 'Heading',
  overridesResolver: (styles) => styles.heading,
})(
  () => `
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`,
)

export const ModalHeading = styled('header', {
  name: 'ModalFullscreen',
  slot: 'Header',
  overridesResolver: (styles) => styles.header,
})(
  ({ theme }) => `
  z-index: ${theme.display.modalZIndex};
  background-color: ${theme.colours.foreground};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${theme.spacing(8)};
  border-bottom: 1px solid ${theme.colours.baseLight};
`,
)

export const CloseButtonLarge = styled('button', {
  name: 'ModalFullscreen',
  slot: 'CloseButton',
  overridesResolver: (styles) => styles.closeButton,
})(
  ({ theme }) => `
  ${closeButtonShared(theme)}
  height: 100%;
  & > svg {
    width: 20px;
  }
`,
)

export const ModalFullscreenContent = styled('div', {
  name: 'ModalFullscreen',
  slot: 'Content',
  overridesResolver: (styles) => styles.content,
})(
  ({ theme }) => `
  padding-top: ${theme.spacing(8)};
  height: 100%;
  overflow-y: auto;
`,
)

// Modal TitleBar
export const ModalPanel = styled('div')<{
  backgroundColor: string
  paddingHeader: string | undefined

  headingBorderBottom: boolean
}>(
  ({ theme, backgroundColor, paddingHeader, headingBorderBottom }) => `
  ${modalBodyShared(theme)}
  height: auto;
  width: 100%;
  ${paddingHeader ? `padding: ${paddingHeader}` : `padding: ${theme.spacing(4)}`};
  padding-bottom: ${theme.spacing(2)};
  border-top: 1px solid ${theme.colours.baseLight};
  border-top-left-radius: ${theme.display.borderRadius};
  border-top-right-radius: ${theme.display.borderRadius};
  background-color: ${backgroundColor ? backgroundColor : ''};
  ${headingBorderBottom && `border-bottom: 1px solid ${theme.colours.baseLight}`};
  

  &.modal-reduced-padding {
    padding: ${theme.display.modalPaddingSmallest} ${theme.display.modalPaddingSmallest};
  }
  z-index: ${theme.display.modalZIndex + 1};

  &.headerNoBorder {
    border-bottom: none;
  }
`,
)

export const ModalPanelContainer = styled('div')(
  () => `
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
`,
)

export const ModalFooter = styled('div')<{
  className?: string
  paddingFooter?: string | undefined
}>(
  ({ theme, paddingFooter }) => `
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${theme.colours.foreground};
  ${paddingFooter ? `padding: ${paddingFooter}` : `padding: ${theme.spacing(2)}`};
  border-top: 1px solid ${theme.colours.baseLight};
  border-bottom-left-radius: ${theme.display.borderRadius};
  border-bottom-right-radius: ${theme.display.borderRadius};

  &.modal-reduced-padding {
    padding: ${theme.display.modalPaddingSmallest} ${theme.display.modalPaddingSmallest};
  }
`,
)

export const TransformModal = styled('div')<{
  transformation: ModalTransformation
}>(
  ({ theme, transformation }) => `
    ${
      transformation === 'globalSearch'
        ? `
      & *.modal-overlay {
        align-items: flex-start;
      }
      & *.modal-inner-container {
        max-width: 552px;
        padding: 0;
      }
      & *.modal-panel {
        display: none;
      }
      & *.modal-body {
        padding: 0;
        margin: ${theme.spacing(2)} 0 0 0;
        border-radius: ${theme.display.borderRadius};
        & > div {
          margin: 0;
        }
        width: 552px;
      }
    `
        : ''
    };
`,
)
