import * as yup from 'yup'

import {
  ERROR_DATE_GENERIC,
  ERROR_EMAIL,
  ERROR_FUTURE,
  ERROR_OVER_AGE,
  ERROR_PHONE,
  ERROR_REQUIRED,
  ERROR_UNDER_AGE,
  isAboveMinimumAge,
  isBelowMaximumAge,
  isEmailValid,
  isInTheFuture,
  isPhoneNumValid,
  isProtectedDate,
  isValidDate,
  UKAgeLimit,
  unwrapErrors,
} from '@acre/utils'

export const contactValidationSchema = yup.object().shape({
  date_of_birth: yup
    .string()
    .nullable()
    .notRequired()
    .test('isValid', ERROR_DATE_GENERIC, (value) => !value || isProtectedDate(value) || isValidDate(value))
    .test('isAboveMinimumAge', ERROR_UNDER_AGE, (value) => (value ? !isAboveMinimumAge(value, UKAgeLimit) : true))
    .test('isBelowMaximumAge', ERROR_OVER_AGE, (value) => (value ? !isBelowMaximumAge(value) : true))
    .test('isInTheFuture', ERROR_FUTURE, (value) => (value ? !isInTheFuture(value) : true)),
  contact_details_email: yup
    .string()
    .email(ERROR_EMAIL)
    .nullable()
    .notRequired()
    .test('isValid', ERROR_EMAIL, (value) => (value ? isEmailValid(value) : true))
    .test('emailValidator', ERROR_REQUIRED, function (this, value) {
      const contact_details_mobile_phone: string = this.resolve(yup.ref('contact_details_mobile_phone'))
      const contact_details_email: string = this.resolve(yup.ref('contact_details_email'))

      if (value) {
        return true
      }
      return isEmailValid(contact_details_email) || isPhoneNumValid(contact_details_mobile_phone)
    }),
  contact_details_mobile_phone: yup
    .string()
    .nullable()
    .notRequired()
    .test('isValid', ERROR_PHONE, (value) => (value ? isPhoneNumValid(value) : true))
    .test('Phone', ERROR_REQUIRED, function (this, value) {
      const contact_details_email: string = this.resolve(yup.ref('contact_details_email'))

      const contact_details_mobile_phone: string = this.resolve(yup.ref('contact_details_mobile_phone'))

      if (value) {
        return true
      }
      return isEmailValid(contact_details_email) || isPhoneNumValid(contact_details_mobile_phone)
    }),
})

export const validateContact = unwrapErrors(contactValidationSchema)
