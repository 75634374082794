import React, { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { UserProvider } from '@introducers-contexts'

import { PostBrowserIdDocument, PostBrowserIdMutation, useGetCurrentUserQuery } from '@acre/graphql'
import { FullPageLoadingSpinner, UserTracker } from '@acre/design-system'

import Router from './routing/Router'

const App = () => {
  const [checkBrowserId] = useMutation<PostBrowserIdMutation>(PostBrowserIdDocument)

  useEffect(() => {
    checkBrowserId()
  }, [checkBrowserId])

  const { loading: loadingCurrentUser, data: dataCurrentUser } = useGetCurrentUserQuery()

  const currentUser = dataCurrentUser ? dataCurrentUser.currentUser : null

  if (loadingCurrentUser) {
    return <FullPageLoadingSpinner />
  }

  return (
    <UserProvider value={currentUser}>
      <UserTracker user={currentUser}>
        <Router />
      </UserTracker>
    </UserProvider>
  )
}

export default App
