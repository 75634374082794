import { styled } from '@mui/material'

export const ExternalLink = styled('a')<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  color: ${({ theme }) => theme.colours.baseMid};
  transition: all 0.15s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    transition: all 0.15s ease-in-out;
    background-color: ${({ theme }) => theme.colours.foreground};
  }

  &.selected {
    color: ${({ theme }) => theme.colours.primary};
    &::after {
      background-color: ${({ theme }) => theme.colours.primary};
    }
  }

  &:hover {
    text-decoration: none;
  }
`
