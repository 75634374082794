import { Address, AddressAndFieldUpdates, LutherAddress } from '@acre/graphql'

// TODO FRON-1432 Move duplicated AddressLookup component logic to components workspace
export const convertToLutherAddress = (address: Address): LutherAddress => ({
  address1: address?.line_1,
  address2: address?.line_2,
  address3: address?.line_3,
  postcode: address?.postcode,
  posttown: address?.town,
  county: address?.county,
  country: address?.country,
})

export const formatLutherAddress = (address: LutherAddress): AddressAndFieldUpdates => ({
  line_1: address?.address1,
  line_2: address?.address2,
  line_3: address?.address3,
  postcode: address?.postcode,
  town: address?.posttown,
  county: address?.county,
  country: address?.country,
})
