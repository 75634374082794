import { isNil, omit } from 'lodash'
import * as yup from 'yup'

import { ERROR_INVALID_FORMAT, ERROR_REQUIRED, isValidPostcode, unwrapErrors } from '@acre/utils'
import { LutherAddress } from '@acre/graphql'

// Treat address as empty if all address fields except country are not set
export const isAddressEmpty = (address: LutherAddress) =>
  Object.values(omit(address, ['country'])).every((value) => isNil(value) || value === '')

export const addressValidationSchema = yup.object<LutherAddress>({
  address1: yup.string().nullable().required(ERROR_REQUIRED),
  address2: yup.string().nullable().notRequired(),
  address3: yup.string().nullable().notRequired(),
  posttown: yup.string().nullable().required(ERROR_REQUIRED),
  county: yup.string().nullable().notRequired(),
  postcode: yup
    .string()
    .nullable()
    .required(ERROR_REQUIRED)
    .test('postcode', ERROR_INVALID_FORMAT, (value) => !value || isValidPostcode(value)),
  country: yup.string().nullable().notRequired(),
})

export const optionalAddressValidationSchema = addressValidationSchema
  .transform((value) => {
    if (value && !isAddressEmpty(value)) {
      return value
    }
    return undefined
  })
  .default(undefined)
  .notRequired()
  .nullable()

export const validateAddress = unwrapErrors(addressValidationSchema)
