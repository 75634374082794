import React from 'react'
import MainNav from '@introducers-common'
import { helmetData } from '@introducers-utils/helmetData'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import { Outlet, useLocation } from 'react-router-dom'

import { useGetCurrentUserQuery } from '@acre/graphql'
import { AppLayout, FullPageLoadingSpinner, PageTracker } from '@acre/design-system'

import Eula from './pages/Eula'
import { useEula } from './pages/Eula/Eula.helpers'

const AppRoutes = () => {
  const { data } = useGetCurrentUserQuery({ fetchPolicy: 'network-only' })
  const location = useLocation()

  const user = data?.currentUser

  const { loading, signedEula, doc } = useEula({
    user: user,
  })

  if (loading) {
    return <FullPageLoadingSpinner />
  }

  const isLoginPage = location.pathname === '/login'
  const className = classNames('router', {
    'router--navigation-spacer': !isLoginPage,
  })

  let title = location.pathname.split('/').pop()?.replace(/-/g, ' ') || 'unknown'
  title = title.substring(0, 1).toUpperCase() + title.substring(1, 999)

  return (
    <PageTracker helmetData={helmetData}>
      {doc?.data_base64 && !signedEula && <Eula />}
      <div className={className}>
        <Helmet titleTemplate="Acre Introducers Portal" helmetData={helmetData}>
          <title>{title}</title>
        </Helmet>
        <AppLayout>
          {!isLoginPage && <MainNav />}
          <Outlet />
        </AppLayout>
      </div>
    </PageTracker>
  )
}

export default AppRoutes
